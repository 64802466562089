import React from 'react';
import styled from 'styled-components';
import { SalesPrice, ListPrice } from '../atoms/price';
import Installment from '../atoms/installment';
import Discount from '../atoms/discount-badge';

const PriceInfo = ({ price, className, stamps }) => (
  <Wrapper className={className}>
    <Container>
      <SalesPrice {...price} />
      <WrapperDiscount>
        <Discount {...price} />
      </WrapperDiscount>
    </Container>
    <ListPriceWithMargin {...price} />
    <Installment {...price} />
  </Wrapper>
);

export default PriceInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const WrapperDiscount = styled.div`
  margin-left: 6px;
`;

const ListPriceWithMargin = styled(ListPrice)`
  margin-bottom: 4px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  height: 17px;
`;
