import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@catalogo/theme-text';

export const SalesPrice = ({ price }) => !!price && <Price>{price}</Price>;

export const ListPrice = ({ promotionalPrice, className }) =>
  !!promotionalPrice && <PromotionalPrice className={className}>{promotionalPrice}</PromotionalPrice>;

const Price = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: 12px;
    text-decoration: line-through;
    font-weight: 400;
  `}
`;

const PromotionalPrice = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: 16px;
    font-weight: 700;
  `}
`;

export default SalesPrice;
