import React, { ComponentType, ReactNode } from 'react';
import { getImage } from '@catalogo/service-americanas-common/src/helpers/image';
import { getIn } from 'seamless-immutable';
import { useProductImage } from '../hooks/productImage';

export interface WithProductImageProps {
  children: ReactNode;
  publication: {
    productId: number;
    options: string;
    href: string;
  };
  routeId: string;
  index: number;
  position: number;
}

export const withProductImage = (WrappedComponent: ComponentType) => {
  const EnhancedProductImage = ({ publication, routeId, index, position, ...props }: WithProductImageProps) => {
    const { productId, options, href } = publication;
    const { data } = useProductImage({ productId });

    const productImage = getIn(data, ['product', 'images', [0]], {});
    const image = getImage(productImage, 'large');

    const isOnlyImage = options === 'Só Imagem';

    const componentProps = {
      publication: publication,
      image: image,
      url: href,
      productId: productId,
      isOnlyImage: isOnlyImage,
      routeId: routeId,
      index: index,
      position: position,
    };

    const EnhancedComponent = WrappedComponent as React.FC;

    return <EnhancedComponent {...componentProps} {...props} />;
  };

  return EnhancedProductImage;
};
