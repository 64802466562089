import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel-with-bullets';
import RecProductCard from '@catalogo/ui-americanas-desktop-rec-product-card';
import recProductCardQuery from '@catalogo/service-americanas-product-card/src/queries/rec-product-card.graphql';
import Placeholder from '@catalogo/theme-placeholder';

class Carousel extends React.Component {
  render() {
    const { publication, position, routeId, placeholderForced, placeholderCount } = this.props;

    if (!publication) {
      if (placeholderForced) {
        return <Placeholder count={placeholderCount} />;
      }
      return null;
    }
    return (
      <Container>
        <Title>{publication.title}</Title>
        <TemplateCarousel gap={20}>
          {publication.products.map(({ id, trackingUrl, queryString, sellerId, isAd }, index) => (
            <RecProductCard
              routeId={routeId}
              key={id}
              index={index}
              id={id}
              query={recProductCardQuery}
              sellerId={sellerId}
              queryString={queryString}
              trackingUrl={trackingUrl}
              position={position}
              publication={publication}
              isRec={true}
              isAd={isAd}
            />
          ))}
        </TemplateCarousel>
      </Container>
    );
  }
}

Carousel.propTypes = {
  publication: PropTypes.object,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const Title = styled.h2`
  text-transform: lowercase;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.fontColor || '#666'};
  padding: 20px 0px 10px 10px;
  @media (min-width: 768px) {
    font-size: 26px;
  }
`;

export default Carousel;
