import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';

const CardImage = ({ imageSource, name, className }) => (
  <WrapperPicture className={className}>
    <LazyPicture sources={imageSource} alt={name} backgroundColor="#fafafa" errorPlaceholderSize="150px" />
  </WrapperPicture>
);

const WrapperPicture = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  width: calc(100% - 32px);
`;

export default CardImage;
