import React from 'react';
import styled, { css } from 'styled-components';
import Rating from '@catalogo/ui-americanas-desktop-rating';
import ProductName from '../atoms/product-name';
import AdsBadge from '../atoms/ads-badge';

const ProductInfo = ({ className, rating, ...cardProps }) => (
  <Wrapper className={className}>
    <ContainerBadged gap="16px">
      <AdsBadge {...cardProps} />
    </ContainerBadged>
    <Container gap="8px">
      <ProductName {...cardProps} />
    </Container>
    <Rating rating={rating} color="#999999" fontSize="12px" />
  </Wrapper>
);

export default ProductInfo;

const Container = styled.div`
  margin-bottom: 16px;
  ${({ gap }) => css`
    display: grid;
    gap: ${gap};
  `}
`;

const ContainerBadged = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  &:empty {
    height: 24px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
