import React from 'react';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';

const FreightTag = ({ stamps }) => {
  const isFreeFreight = getIn(stamps, ['isFreeFreight'], false);
  return !!isFreeFreight && <DeliveryBadge>frete grátis</DeliveryBadge>;
};

const DeliveryBadge = styled.div`
  ${({ theme }) => css`
    color: ${theme.card.badge};
    font-size: 12px;
    display: flex;
    align-items: center;
    min-height: 20px;
    width: fit-content;
  `}
`;

export default FreightTag;
