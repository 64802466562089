import React from 'react';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';

const AdsBadge = ({ stamps }) => {
  const isAd = getIn(stamps, ['isAd'], false);
  return (
    !!isAd && (
      <Badge>
        <BadgeText>patrocinado</BadgeText>
      </Badge>
    )
  );
};

const Badge = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.grey.lighter};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 8px;
    border-radius: 4px;
    width: 95px;
  `}
`;

const BadgeText = styled.p`
  ${({ theme }) => css`
    color: ${theme.grey.darker};
    font-size: ${theme.fontSize.xs};
  `}
`;

export default AdsBadge;
