import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import PriceInfo from '../molecules/price-info';
import ImageInfo from '../molecules/image-info';
import BadgesInfo from '../molecules/badges-info';
import ProductInfo from '../molecules/product-info';

const InStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfoUI {...props} />
      <ProductInfoUI {...props} />
      <PriceInfoUI {...props} />
      <BadgesInfo {...props} />
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div``;

export default InStockCard;

const ImageInfoUI = styled(ImageInfo)`
  margin-bottom: 16px;
`;

const PriceInfoUI = styled(PriceInfo)`
  margin-bottom: 12px;
  padding: 0 16px;
`;

const ProductInfoUI = styled(ProductInfo)`
  margin-bottom: 16px;
  padding: 0 16px;
`;
