import React from 'react';
import styled from 'styled-components';
import ArrowDown from '@catalogo/ui-americanas-desktop-svg/arrow_down.svg';

const DiscountBadge = ({ discount }) =>
  !!discount && (
    <Container>
      <ArrowDiscount width="10" height="10" fill="#fff" />
      <Text>{discount}</Text>
    </Container>
  );

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.card.green};
  border-radius: 16px;
  padding: 3px 5px;
`;

const ArrowDiscount = styled(ArrowDown)`
  width: 10px;
  height: 10px;
`;

const Text = styled.span`
  margin-left: 2px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  font-size: 12px;
`;

export default DiscountBadge;
