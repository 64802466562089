import React from 'react';
import styled from 'styled-components';
import FreightTag from '../atoms/freight-tag';
import CachbackTag from '../atoms/cashback-tag';

export const BadgesInfo = ({ stamps }) => (
  <Wrapper>
    <FreightTag stamps={stamps} />
    <CachbackTag stamps={stamps} />
  </Wrapper>
);

const Wrapper = styled.div`
  padding-left: 16px;
  display: grid;
  gap: 4px;
  margin-bottom: 16px;
  &:empty {
    display: none;
  }
`;

export default BadgesInfo;
