import React from 'react';
import styled from 'styled-components';

const EventStamp = ({ stamps, className }) => {
  const eventStamps = stamps?.eventStamp;

  return !!eventStamps && <EventStampImg src={eventStamps} className={className} />;
};

const EventStampImg = styled.img`
  width: 60px;
  height: 60px;
`;

export default EventStamp;
