import React from 'react';
import styled from 'styled-components';
import { getIn } from 'seamless-immutable';

const CachbackTag = ({ stamps }) => {
  const isSuperCashback = getIn(stamps, ['isSuperCashback'], false);
  return !!isSuperCashback && <DeliveryBadge>super cashback</DeliveryBadge>;
};

const DeliveryBadge = styled.div`
  color: #ed0059;
  font-size: 12px;
  display: flex;
  align-items: center;
  min-height: 20px;
  width: fit-content;
`;

export default CachbackTag;
