import React from 'react';
import styled from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import ProductInfo from '../molecules/product-info';
import ImageInfo from '../molecules/image-info';

const OutofStockCard = props => (
  <Wrapper>
    <Nav to={props.url}>
      <ImageInfoUI {...props} />
      <ProductInfoUI {...props} />
      <OutOfStockWrapper>
        <OutOfStockText>Ops! Já vendemos todo o estoque.</OutOfStockText>
      </OutOfStockWrapper>
    </Nav>
  </Wrapper>
);

const Wrapper = styled.div`
  margin: 0px auto;
`;

const ImageInfoUI = styled(ImageInfo)`
  margin-bottom: 12px;
`;
const ProductInfoUI = styled(ProductInfo)`
  margin-bottom: 16px;
  padding: 0 16px;
`;

const OutOfStockWrapper = styled.div`
  margin-top: 13px;
  color: ${({ theme }) => theme.grey.dark};
  padding: 33px 16px;
  white-space: normal;
  border-radius: 4px;
`;

const OutOfStockText = styled.span`
  font-size: 14px;
`;

export default OutofStockCard;
