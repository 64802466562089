import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'seamless-immutable';
import { Observer } from '@catalogo/theme-observer';
import { useLazyQuery } from '@apollo/client';
import { withMetrics } from '@catalogo/core-metrics';
import { withOneDayDelivery } from '@catalogo/core-connect/one-day-delivery';
import { withCookies } from '@catalogo/core-connect/cookies';
import compose from 'lodash/fp/compose';
import { sendBeacon } from '@catalogo/core-send-beacon';
import { getVibraVariablesToTax } from '@catalogo/core-vibra';
import { getSellerId } from '@catalogo/core-consumption-type';
import { fmtProductWithTax } from '@catalogo/core-b2b-product-tax';
import { hasOffers } from './helpers/product';

const withProductHOC = WrappedComponent => {
  const Component = ({ dispatchMetrics, ...props }) => {
    const { id, sellerId: sellerIdFromProps, cookies } = props;
    const { isVibra, region, warehouse } = getVibraVariablesToTax(cookies);
    const sellerId = getSellerId(cookies, sellerIdFromProps);
    const onCompleted = /* istanbul ignore next */ data => {
      const { product } = data;
      if (!hasOffers(product)) {
        dispatchMetrics('outOfStock:load', { props, product });
      }
    };
    const [fetchProduct, { loading, error, called, refetch, data }] = useLazyQuery(props.query, { onCompleted });
    const product = getIn(data, ['product'], {});
    const productWithTax = fmtProductWithTax(props.product || product);

    const onceVisible = () => {
      fetchProduct({ variables: { productId: id, sellerId, promoted: true, isVibra, region, warehouse } });
    };

    const onClickProduct = () => {
      const { position, index, trackingUrl } = props;
      const { offers } = productWithTax;

      const prime = getIn(offers, ['result', 0, 'stamps', 'prime']);
      const bestPaymentOption = getIn(offers, ['result', 0, 'bestPaymentOption']);
      const salesPrice =
        getIn(bestPaymentOption, ['minInstallment', 0, 'total']) || getIn(bestPaymentOption, ['price']);
      const oneDayDelivery = getIn(offers, ['result', 0, 'stamps', 'oneDayDelivery'], []);
      const offerId = getIn(offers, ['result', 0, 'offerId'], '');

      if (trackingUrl) {
        sendBeacon(trackingUrl);
      }

      dispatchMetrics('product:click', { position, id, index, salesPrice, prime, oneDayDelivery, offerId });
    };

    if (props.product) {
      return (
        <div onClick={onClickProduct} className="card">
          <WrappedComponent loading={loading} called={called} error={error} {...props} product={productWithTax} />
        </div>
      );
    }

    if (error) {
      error.retry = () => refetch({ variables: { productId: id, sellerId, promoted: true } });
    }

    return (
      <Observer onceVisible={() => onceVisible()}>
        <div onClick={onClickProduct} className="card">
          <WrappedComponent loading={loading} called={called} error={error} product={productWithTax} {...props} />
        </div>
      </Observer>
    );
  };

  Component.propTypes = {
    id: PropTypes.string,
    sellerId: PropTypes.string,
    product: PropTypes.object,
    query: PropTypes.object,
  };

  return Component;
};

export const withProduct = compose(withMetrics, withOneDayDelivery, withCookies, withProductHOC);
