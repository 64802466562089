import React from 'react';
import styled from 'styled-components';
import CardImage from '../atoms/card-image';
import EventStamps from '../atoms/event-stamps';

const ProductInfo = ({ className, ...cardProps }) => (
  <Wrapper className={className}>
    <EventStampsPosition {...cardProps} />
    <CardImageMargin {...cardProps} />
  </Wrapper>
);

export default ProductInfo;

const Wrapper = styled.div`
  position: relative;
  background: #fafafa;
`;

const EventStampsPosition = styled(EventStamps)`
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 2;
`;

const CardImageMargin = styled(CardImage)`
  margin: 0 auto;
  max-width: 180px;
  position: relative;
`;
