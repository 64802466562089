import React from 'react';
import styled, { css } from 'styled-components';

const Installment = ({ installmentPhase }) => !!installmentPhase && <InstallmentUI>{installmentPhase}</InstallmentUI>;

const InstallmentUI = styled.span`
  ${({ theme }) => css`
    color: ${theme.grey.dark};
    font-size: 12px;
    height: 28px;
  `}
`;

export default Installment;
